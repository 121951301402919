import { graphql } from 'gatsby'
import React from 'react'

import { PortfolioQueryQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Portfolio from '../components/portfolio'
import Appointment from '../components/appointment'

interface Props {
  data: PortfolioQueryQuery
  location: Location
}

const Index: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Check out our portfolio in Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Portfolio />
      <Appointment />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query PortfolioQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
