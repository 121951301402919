import React from 'react'
import _get from 'lodash/get'

const Portfolio: React.FC = () => {
  return (
    <section
      id="portfolio"
      className="ls ms portfolio-section s-pt-60 s-pb-60 s-pt-lg-110 s-pb-lg-90  s-pt-xl-150 s-pb-xl-140 s-overlay s-parallax container-px-xl-165 container-px-lg-100 container-px-md-10 c-gutter-70"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 text-center text-lg-left">
            <div className="title-portfolio">
              <span className="sub-title">Check Out</span>
              <h1 className="special-heading">Our Instagram Portfolio</h1>
              <p>
                Here are some of the client treatments we are proud to have
                completed.{' '}
              </p>
            </div>
          </div>
          <h3 className="col-xl-7" style={{ textAlign: 'center' }}>
            <a
              href="https://www.instagram.com/ada_aesthetics_uk"
              target="_blank"
              rel="noreferrer"
            >
              Please check - Instagram Gallery
            </a>
          </h3>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
